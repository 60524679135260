
function Future()
{
  return (
    <section className="elementor-section elementor-top-section elementor-element elementor-element-91a39ed elementor-section-boxed elementor-section-height-default elementor-section-height-default"
             data-id="91a39ed" data-element_type="section">
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fad9642"
             data-id="fad9642" data-element_type="column">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-25df52b6 elementor-widget elementor-widget-heading"
                 data-id="25df52b6" data-element_type="widget" data-widget_type="heading.default">
              <div className="elementor-widget-container">
                <h3 className="elementor-heading-title elementor-size-default">
                  the moment is now
                </h3>
              </div>
            </div>
            <div className="elementor-element elementor-element-680d7a47 elementor-widget elementor-widget-heading"
                 data-id="680d7a47" data-element_type="widget" data-widget_type="heading.default">
              <div className="elementor-widget-container">
                <h3 className="elementor-heading-title elementor-size-default">
                  Building the Future of STEM Education
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Future;
