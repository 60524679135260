
import './App.css';  // AAJ999 NEEDED?

import Beginning from './Beginning.js';
import Footer    from './Footer.js';
import Hero      from './Hero.js';
import About     from './About.js';
import Products  from './Products.js';
import Services  from './Services.js';
import Future    from './Future.js';
import Rocket    from './Rocket.js';
import Contact   from './Contact.js';

function App()
{
  return (
    <div>
      <a className="skip-link screen-reader-text" href="#content">
        Skip to content
      </a>

      <Beginning />

      <main id="content" className="site-main post-2 page type-page status-publish hentry">
        <div className="page-content">
          <div data-elementor-type="wp-page" data-elementor-id="2"
               className="elementor elementor-2" data-elementor-post-type="page">

            <Hero />
            <About />
            <Products />
            <Services />
            <Future />
            <Rocket />
            <Contact />

          </div>
        </div>
      </main>

      <Footer />

    </div>
  );
}

export default App;
