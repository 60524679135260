
import { Style } from 'react-style-tag';

function About()
{
  return (
    <section className="elementor-section elementor-top-section elementor-element elementor-element-21aaa054 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
             data-id="21aaa054" data-element_type="section" id="about-us">
      <div className="elementor-container elementor-column-gap-custom">
        <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-594a3b03"
             data-id="594a3b03" data-element_type="column">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-5a9d0bb elementor-widget-divider--separator-type-pattern elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                 data-id="5a9d0bb" data-element_type="widget" data-widget_type="divider.default">
              <div className="elementor-widget-container">
                <Style>{`
                  .elementor-widget-divider {
                    --divider-border-style: none;
                    --divider-border-width: 1px;
                    --divider-color: #0c0d0e;
                    --divider-icon-size: 20px;
                    --divider-element-spacing: 10px;
                    --divider-pattern-height: 24px;
                    --divider-pattern-size: 20px;
                    --divider-pattern-url: none;
                    --divider-pattern-repeat: repeat-x
                  }

                  .elementor-widget-divider .elementor-divider {
                    display: flex
                  }

                  .elementor-widget-divider .elementor-divider__text {
                    font-size: 15px;
                    line-height: 1;
                    max-width: 95%
                  }

                  .elementor-widget-divider .elementor-divider__element {
                    margin: 0 var(--divider-element-spacing);
                    flex-shrink: 0
                  }

                  .elementor-widget-divider .elementor-icon {
                    font-size: var(--divider-icon-size)
                  }

                  .elementor-widget-divider .elementor-divider-separator {
                    display: flex;
                    margin: 0;
                    direction: ltr
                  }

                  .elementor-widget-divider--view-line_icon .elementor-divider-separator,
                  .elementor-widget-divider--view-line_text .elementor-divider-separator {
                    align-items: center
                  }

                  .elementor-widget-divider--view-line_icon .elementor-divider-separator:after,
                  .elementor-widget-divider--view-line_icon .elementor-divider-separator:before,
                  .elementor-widget-divider--view-line_text .elementor-divider-separator:after,
                  .elementor-widget-divider--view-line_text .elementor-divider-separator:before {
                    display: block;
                    content: "";
                    border-bottom: 0;
                    flex-grow: 1;
                    border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color)
                  }

                  .elementor-widget-divider--element-align-left .elementor-divider .elementor-divider-separator>.elementor-divider__svg:first-of-type {
                    flex-grow: 0;
                    flex-shrink: 100
                  }

                  .elementor-widget-divider--element-align-left .elementor-divider-separator:before {
                    content: none
                  }

                  .elementor-widget-divider--element-align-left .elementor-divider__element {
                    margin-left: 0
                  }

                  .elementor-widget-divider--element-align-right .elementor-divider .elementor-divider-separator>.elementor-divider__svg:last-of-type {
                    flex-grow: 0;
                    flex-shrink: 100
                  }

                  .elementor-widget-divider--element-align-right .elementor-divider-separator:after {
                    content: none
                  }

                  .elementor-widget-divider--element-align-right .elementor-divider__element {
                    margin-right: 0
                  }

                  .elementor-widget-divider:not(.elementor-widget-divider--view-line_text):not(.elementor-widget-divider--view-line_icon) .elementor-divider-separator {
                    border-top: var(--divider-border-width) var(--divider-border-style) var(--divider-color)
                  }

                  .elementor-widget-divider--separator-type-pattern {
                    --divider-border-style: none
                  }

                  .elementor-widget-divider--separator-type-pattern.elementor-widget-divider--view-line .elementor-divider-separator,
                  .elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:after,
                  .elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:before,
                  .elementor-widget-divider--separator-type-pattern:not([class*=elementor-widget-divider--view]) .elementor-divider-separator {
                    width: 100%;
                    min-height: var(--divider-pattern-height);
                    -webkit-mask-size: var(--divider-pattern-size) 100%;
                    mask-size: var(--divider-pattern-size) 100%;
                    -webkit-mask-repeat: var(--divider-pattern-repeat);
                    mask-repeat: var(--divider-pattern-repeat);
                    background-color: var(--divider-color);
                    -webkit-mask-image: var(--divider-pattern-url);
                    mask-image: var(--divider-pattern-url)
                  }

                  .elementor-widget-divider--no-spacing {
                    --divider-pattern-size: auto
                  }

                  .elementor-widget-divider--bg-round {
                    --divider-pattern-repeat: round
                  }

                  .rtl .elementor-widget-divider .elementor-divider__text {
                    direction: rtl
                  }

                  .e-con-inner>.elementor-widget-divider,
                  .e-con>.elementor-widget-divider {
                    width: var(--container-widget-width, 100%);
                    --flex-grow: var(--container-widget-flex-grow)
                  }
                `}</Style>
                <div className="elementor-divider my-divider-pattern-1">
                  <span className="elementor-divider-separator">
                  </span>
                </div>
              </div>
            </div>
            <div className="elementor-element elementor-element-d213d45 elementor-widget__width-initial elementor-widget elementor-widget-heading"
                 data-id="d213d45" data-element_type="widget" data-widget_type="heading.default">
              <div className="elementor-widget-container">
                <h3 className="elementor-heading-title elementor-size-default">
                  About Us
                </h3>
              </div>
            </div>
            <div className="elementor-element elementor-element-6f5f539 elementor-widget__width-initial elementor-widget-tablet__width-inherit elementor-widget elementor-widget-text-editor"
                 data-id="6f5f539" data-element_type="widget"
                 data-widget_type="text-editor.default">
              <div className="elementor-widget-container">
                <p>
                  Digital Rocket Science’s mission is to bring advanced numerical simulation
                  and modeling tools to the classroom in easy-to-use and highly accessible
                  web-based frameworks for learning, demonstration, and experimentation by
                  students in STEM fields.
                </p>
                <p>
                  These “virtual laboratories” are implemented using modern web-based
                  technology and algorithms that are highly visual and high-performing so that
                  experiments can be carried out in real-time. The simulation platforms
                  exposes students to modern scientific and engineering methodologies used by
                  professionals in many fields while also providing insight into physical
                  phenomena through experimentation and analysis.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-51174e78"
             data-id="51174e78" data-element_type="column">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-35d10ebe elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-invisible elementor-widget elementor-widget-image"
                 data-id="35d10ebe" data-element_type="widget"
                 data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;}"
                 data-widget_type="image.default">
              <div className="elementor-widget-container">
                <img decoding="async" width="470" height="470"
                     src="./images/teen-sitting-rocket-1.jpg"
                     className="attachment-full size-full wp-image-209" alt=""
                     srcSet="./images/teen-sitting-rocket-1.jpg 470w, ./images/teen-sitting-rocket-1-300x300.jpg 300w, ./images/teen-sitting-rocket-1-150x150.jpg 150w"
                     sizes="(max-width: 470px) 100vw, 470px" />
              </div>
            </div>
            <div className="elementor-element elementor-element-1c178294 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-invisible elementor-widget elementor-widget-image"
                 data-id="1c178294" data-element_type="widget"
                 data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}"
                 data-widget_type="image.default">
              <div className="elementor-widget-container">
                <img decoding="async" width="288" height="288" src="./images/image-2.jpg"
                     className="attachment-full size-full wp-image-118" alt=""
                     srcSet="./images/image-2.jpg 288w, ./images/image-2-150x150.jpg 150w"
                     sizes="(max-width: 288px) 100vw, 288px" />
              </div>
            </div>
            <div className="elementor-element elementor-element-2f464221 elementor-invisible elementor-widget elementor-widget-image"
                 data-id="2f464221" data-element_type="widget"
                 data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;}"
                 data-widget_type="image.default">
              <div className="elementor-widget-container">
                <img decoding="async" loading="lazy" width="532" height="254"
                     src="./images/teen-sitting-looking-1.jpg"
                     className="attachment-full size-full wp-image-210" alt=""
                     srcSet="./images/teen-sitting-looking-1.jpg 532w, ./images/teen-sitting-looking-1-300x143.jpg 300w"
                     sizes="(max-width: 532px) 100vw, 532px" />
              </div>
            </div>
            <div className="elementor-element elementor-element-1b6c1a5e elementor-widget__width-auto elementor-absolute animated-slow elementor-view-default elementor-invisible elementor-widget elementor-widget-icon"
                 data-id="1b6c1a5e" data-element_type="widget"
                 data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:500,&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateX_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateX_direction&quot;:&quot;negative&quot;,&quot;motion_fx_translateX_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:2,&quot;sizes&quot;:[]},&quot;motion_fx_translateX_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:50,&quot;end&quot;:100}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}"
                 data-widget_type="icon.default">
              <div className="elementor-widget-container">
                <div className="elementor-icon-wrapper">
                  <div className="elementor-icon">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         id="afb6f239-ddfa-4317-a8c2-6e12c8554240" data-name="Layer 1"
                         width="165.953" height="115.814" viewBox="0 0 165.953 115.814">
                      <circle cx="1.5" cy="1.5" r="1.5"></circle>
                      <circle cx="1.5" cy="14.0352" r="1.5"></circle>
                      <circle cx="1.5" cy="26.5684" r="1.5"></circle>
                      <circle cx="1.5" cy="39.1035" r="1.5"></circle>
                      <circle cx="1.5" cy="51.6406" r="1.5"></circle>
                      <circle cx="1.5" cy="64.1758" r="1.5"></circle>
                      <circle cx="1.5" cy="76.709" r="1.5"></circle>
                      <circle cx="1.5" cy="89.2441" r="1.5"></circle>
                      <circle cx="1.5" cy="101.779" r="1.5"></circle>
                      <circle cx="1.5" cy="114.314" r="1.5"></circle>
                      <circle cx="14.0312" cy="1.5" r="1.5"></circle>
                      <circle cx="14.0312" cy="14.0352" r="1.5"></circle>
                      <circle cx="14.0312" cy="26.5684" r="1.5"></circle>
                      <circle cx="14.0312" cy="39.1035" r="1.5"></circle>
                      <circle cx="14.0312" cy="51.6406" r="1.5"></circle>
                      <circle cx="14.0312" cy="64.1758" r="1.5"></circle>
                      <circle cx="14.0312" cy="76.709" r="1.5"></circle>
                      <circle cx="14.0312" cy="89.2441" r="1.5"></circle>
                      <circle cx="14.0312" cy="101.779" r="1.5"></circle>
                      <circle cx="14.0312" cy="114.314" r="1.5"></circle>
                      <circle cx="26.5664" cy="1.5" r="1.5"></circle>
                      <circle cx="26.5664" cy="14.0352" r="1.5"></circle>
                      <circle cx="26.5664" cy="26.5684" r="1.5"></circle>
                      <circle cx="26.5664" cy="39.1035" r="1.5"></circle>
                      <circle cx="26.5664" cy="51.6406" r="1.5"></circle>
                      <circle cx="26.5664" cy="64.1758" r="1.5"></circle>
                      <circle cx="26.5664" cy="76.709" r="1.5"></circle>
                      <circle cx="26.5664" cy="89.2441" r="1.5"></circle>
                      <circle cx="26.5664" cy="101.779" r="1.5"></circle>
                      <circle cx="26.5664" cy="114.314" r="1.5"></circle>
                      <circle cx="39.1016" cy="1.5" r="1.5"></circle>
                      <circle cx="39.1016" cy="14.0352" r="1.5"></circle>
                      <circle cx="39.1016" cy="26.5684" r="1.5"></circle>
                      <circle cx="39.1016" cy="39.1035" r="1.5"></circle>
                      <circle cx="39.1016" cy="51.6406" r="1.5"></circle>
                      <circle cx="39.1016" cy="64.1758" r="1.5"></circle>
                      <circle cx="39.1016" cy="76.709" r="1.5"></circle>
                      <circle cx="39.1016" cy="89.2441" r="1.5"></circle>
                      <circle cx="39.1016" cy="101.779" r="1.5"></circle>
                      <circle cx="39.1016" cy="114.314" r="1.5"></circle>
                      <circle cx="51.6367" cy="1.5" r="1.5"></circle>
                      <circle cx="51.6367" cy="14.0352" r="1.5"></circle>
                      <circle cx="51.6367" cy="26.5684" r="1.5"></circle>
                      <circle cx="51.6367" cy="39.1035" r="1.5"></circle>
                      <circle cx="51.6367" cy="51.6406" r="1.5"></circle>
                      <circle cx="51.6367" cy="64.1758" r="1.5"></circle>
                      <circle cx="51.6367" cy="76.709" r="1.5"></circle>
                      <circle cx="51.6367" cy="89.2441" r="1.5"></circle>
                      <circle cx="51.6367" cy="101.779" r="1.5"></circle>
                      <circle cx="51.6367" cy="114.314" r="1.5"></circle>
                      <circle cx="64.1719" cy="1.5" r="1.5"></circle>
                      <circle cx="64.1719" cy="14.0352" r="1.5"></circle>
                      <circle cx="64.1719" cy="26.5684" r="1.5"></circle>
                      <circle cx="64.1719" cy="39.1035" r="1.5"></circle>
                      <circle cx="64.1719" cy="51.6406" r="1.5"></circle>
                      <circle cx="64.1719" cy="64.1758" r="1.5"></circle>
                      <circle cx="64.1719" cy="76.709" r="1.5"></circle>
                      <circle cx="64.1719" cy="89.2441" r="1.5"></circle>
                      <circle cx="64.1719" cy="101.779" r="1.5"></circle>
                      <circle cx="64.1719" cy="114.314" r="1.5"></circle>
                      <circle cx="76.707" cy="1.5" r="1.5"></circle>
                      <circle cx="76.707" cy="14.0352" r="1.5"></circle>
                      <circle cx="76.707" cy="26.5684" r="1.5"></circle>
                      <circle cx="76.707" cy="39.1035" r="1.5"></circle>
                      <circle cx="76.707" cy="51.6406" r="1.5"></circle>
                      <circle cx="76.707" cy="64.1758" r="1.5"></circle>
                      <circle cx="76.707" cy="76.709" r="1.5"></circle>
                      <circle cx="76.707" cy="89.2441" r="1.5"></circle>
                      <circle cx="76.707" cy="101.779" r="1.5"></circle>
                      <circle cx="76.707" cy="114.314" r="1.5"></circle>
                      <circle cx="89.2461" cy="1.5" r="1.5"></circle>
                      <circle cx="89.2461" cy="14.0352" r="1.5"></circle>
                      <circle cx="89.2461" cy="26.5684" r="1.5"></circle>
                      <circle cx="89.2461" cy="39.1035" r="1.5"></circle>
                      <circle cx="89.2461" cy="51.6406" r="1.5"></circle>
                      <circle cx="89.2461" cy="64.1758" r="1.5"></circle>
                      <circle cx="89.2461" cy="76.709" r="1.5"></circle>
                      <circle cx="89.2461" cy="89.2441" r="1.5"></circle>
                      <circle cx="89.2461" cy="101.779" r="1.5"></circle>
                      <circle cx="89.2461" cy="114.314" r="1.5"></circle>
                      <circle cx="101.777" cy="1.5" r="1.5"></circle>
                      <circle cx="101.777" cy="14.0352" r="1.5"></circle>
                      <circle cx="101.777" cy="26.5684" r="1.5"></circle>
                      <circle cx="101.777" cy="39.1035" r="1.5"></circle>
                      <circle cx="101.777" cy="51.6406" r="1.5"></circle>
                      <circle cx="101.777" cy="64.1758" r="1.5"></circle>
                      <circle cx="101.777" cy="76.709" r="1.5"></circle>
                      <circle cx="101.777" cy="89.2441" r="1.5"></circle>
                      <circle cx="101.777" cy="101.779" r="1.5"></circle>
                      <circle cx="101.777" cy="114.314" r="1.5"></circle>
                      <circle cx="114.312" cy="1.5" r="1.5"></circle>
                      <circle cx="114.312" cy="14.0352" r="1.5"></circle>
                      <circle cx="114.312" cy="26.5684" r="1.5"></circle>
                      <circle cx="114.312" cy="39.1035" r="1.5"></circle>
                      <circle cx="114.312" cy="51.6406" r="1.5"></circle>
                      <circle cx="114.312" cy="64.1758" r="1.5"></circle>
                      <circle cx="114.312" cy="76.709" r="1.5"></circle>
                      <circle cx="114.312" cy="89.2441" r="1.5"></circle>
                      <circle cx="114.312" cy="101.779" r="1.5"></circle>
                      <circle cx="114.312" cy="114.314" r="1.5"></circle>
                      <circle cx="126.848" cy="1.5" r="1.5"></circle>
                      <circle cx="126.848" cy="14.0352" r="1.5"></circle>
                      <circle cx="126.848" cy="26.5684" r="1.5"></circle>
                      <circle cx="126.848" cy="39.1035" r="1.5"></circle>
                      <circle cx="126.848" cy="51.6406" r="1.5"></circle>
                      <circle cx="126.848" cy="64.1758" r="1.5"></circle>
                      <circle cx="126.848" cy="76.709" r="1.5"></circle>
                      <circle cx="126.848" cy="89.2441" r="1.5"></circle>
                      <circle cx="126.848" cy="101.779" r="1.5"></circle>
                      <circle cx="126.848" cy="114.314" r="1.5"></circle>
                      <circle cx="139.383" cy="1.5" r="1.5"></circle>
                      <circle cx="139.383" cy="14.0352" r="1.5"></circle>
                      <circle cx="139.383" cy="26.5684" r="1.5"></circle>
                      <circle cx="139.383" cy="39.1035" r="1.5"></circle>
                      <circle cx="139.383" cy="51.6406" r="1.5"></circle>
                      <circle cx="139.383" cy="64.1758" r="1.5"></circle>
                      <circle cx="139.383" cy="76.709" r="1.5"></circle>
                      <circle cx="139.383" cy="89.2441" r="1.5"></circle>
                      <circle cx="139.383" cy="101.779" r="1.5"></circle>
                      <circle cx="139.383" cy="114.314" r="1.5"></circle>
                      <circle cx="151.918" cy="1.5" r="1.5"></circle>
                      <circle cx="151.918" cy="14.0352" r="1.5"></circle>
                      <circle cx="151.918" cy="26.5684" r="1.5"></circle>
                      <circle cx="151.918" cy="39.1035" r="1.5"></circle>
                      <circle cx="151.918" cy="51.6406" r="1.5"></circle>
                      <circle cx="151.918" cy="64.1758" r="1.5"></circle>
                      <circle cx="151.918" cy="76.709" r="1.5"></circle>
                      <circle cx="151.918" cy="89.2441" r="1.5"></circle>
                      <circle cx="151.918" cy="101.779" r="1.5"></circle>
                      <circle cx="151.918" cy="114.314" r="1.5"></circle>
                      <circle cx="164.453" cy="1.5" r="1.5"></circle>
                      <circle cx="164.453" cy="14.0352" r="1.5"></circle>
                      <circle cx="164.453" cy="26.5684" r="1.5"></circle>
                      <circle cx="164.453" cy="39.1035" r="1.5"></circle>
                      <circle cx="164.453" cy="51.6406" r="1.5"></circle>
                      <circle cx="164.453" cy="64.1758" r="1.5"></circle>
                      <circle cx="164.453" cy="76.709" r="1.5"></circle>
                      <circle cx="164.453" cy="89.2441" r="1.5"></circle>
                      <circle cx="164.453" cy="101.779" r="1.5"></circle>
                      <circle cx="164.453" cy="114.314" r="1.5"></circle>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="elementor-element elementor-element-197e442 elementor-widget__width-auto elementor-absolute animated-slow elementor-view-default elementor-invisible elementor-widget elementor-widget-icon"
                 data-id="197e442" data-element_type="widget"
                 data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:400,&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateX_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateX_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:1,&quot;sizes&quot;:[]},&quot;motion_fx_translateX_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:65,&quot;end&quot;:100}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}"
                 data-widget_type="icon.default">
              <div className="elementor-widget-container">
                <div className="elementor-icon-wrapper">
                  <div className="elementor-icon">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         id="afb6f239-ddfa-4317-a8c2-6e12c8554240" data-name="Layer 1"
                         width="165.953" height="115.814" viewBox="0 0 165.953 115.814">
                      <circle cx="1.5" cy="1.5" r="1.5"></circle>
                      <circle cx="1.5" cy="14.0352" r="1.5"></circle>
                      <circle cx="1.5" cy="26.5684" r="1.5"></circle>
                      <circle cx="1.5" cy="39.1035" r="1.5"></circle>
                      <circle cx="1.5" cy="51.6406" r="1.5"></circle>
                      <circle cx="1.5" cy="64.1758" r="1.5"></circle>
                      <circle cx="1.5" cy="76.709" r="1.5"></circle>
                      <circle cx="1.5" cy="89.2441" r="1.5"></circle>
                      <circle cx="1.5" cy="101.779" r="1.5"></circle>
                      <circle cx="1.5" cy="114.314" r="1.5"></circle>
                      <circle cx="14.0312" cy="1.5" r="1.5"></circle>
                      <circle cx="14.0312" cy="14.0352" r="1.5"></circle>
                      <circle cx="14.0312" cy="26.5684" r="1.5"></circle>
                      <circle cx="14.0312" cy="39.1035" r="1.5"></circle>
                      <circle cx="14.0312" cy="51.6406" r="1.5"></circle>
                      <circle cx="14.0312" cy="64.1758" r="1.5"></circle>
                      <circle cx="14.0312" cy="76.709" r="1.5"></circle>
                      <circle cx="14.0312" cy="89.2441" r="1.5"></circle>
                      <circle cx="14.0312" cy="101.779" r="1.5"></circle>
                      <circle cx="14.0312" cy="114.314" r="1.5"></circle>
                      <circle cx="26.5664" cy="1.5" r="1.5"></circle>
                      <circle cx="26.5664" cy="14.0352" r="1.5"></circle>
                      <circle cx="26.5664" cy="26.5684" r="1.5"></circle>
                      <circle cx="26.5664" cy="39.1035" r="1.5"></circle>
                      <circle cx="26.5664" cy="51.6406" r="1.5"></circle>
                      <circle cx="26.5664" cy="64.1758" r="1.5"></circle>
                      <circle cx="26.5664" cy="76.709" r="1.5"></circle>
                      <circle cx="26.5664" cy="89.2441" r="1.5"></circle>
                      <circle cx="26.5664" cy="101.779" r="1.5"></circle>
                      <circle cx="26.5664" cy="114.314" r="1.5"></circle>
                      <circle cx="39.1016" cy="1.5" r="1.5"></circle>
                      <circle cx="39.1016" cy="14.0352" r="1.5"></circle>
                      <circle cx="39.1016" cy="26.5684" r="1.5"></circle>
                      <circle cx="39.1016" cy="39.1035" r="1.5"></circle>
                      <circle cx="39.1016" cy="51.6406" r="1.5"></circle>
                      <circle cx="39.1016" cy="64.1758" r="1.5"></circle>
                      <circle cx="39.1016" cy="76.709" r="1.5"></circle>
                      <circle cx="39.1016" cy="89.2441" r="1.5"></circle>
                      <circle cx="39.1016" cy="101.779" r="1.5"></circle>
                      <circle cx="39.1016" cy="114.314" r="1.5"></circle>
                      <circle cx="51.6367" cy="1.5" r="1.5"></circle>
                      <circle cx="51.6367" cy="14.0352" r="1.5"></circle>
                      <circle cx="51.6367" cy="26.5684" r="1.5"></circle>
                      <circle cx="51.6367" cy="39.1035" r="1.5"></circle>
                      <circle cx="51.6367" cy="51.6406" r="1.5"></circle>
                      <circle cx="51.6367" cy="64.1758" r="1.5"></circle>
                      <circle cx="51.6367" cy="76.709" r="1.5"></circle>
                      <circle cx="51.6367" cy="89.2441" r="1.5"></circle>
                      <circle cx="51.6367" cy="101.779" r="1.5"></circle>
                      <circle cx="51.6367" cy="114.314" r="1.5"></circle>
                      <circle cx="64.1719" cy="1.5" r="1.5"></circle>
                      <circle cx="64.1719" cy="14.0352" r="1.5"></circle>
                      <circle cx="64.1719" cy="26.5684" r="1.5"></circle>
                      <circle cx="64.1719" cy="39.1035" r="1.5"></circle>
                      <circle cx="64.1719" cy="51.6406" r="1.5"></circle>
                      <circle cx="64.1719" cy="64.1758" r="1.5"></circle>
                      <circle cx="64.1719" cy="76.709" r="1.5"></circle>
                      <circle cx="64.1719" cy="89.2441" r="1.5"></circle>
                      <circle cx="64.1719" cy="101.779" r="1.5"></circle>
                      <circle cx="64.1719" cy="114.314" r="1.5"></circle>
                      <circle cx="76.707" cy="1.5" r="1.5"></circle>
                      <circle cx="76.707" cy="14.0352" r="1.5"></circle>
                      <circle cx="76.707" cy="26.5684" r="1.5"></circle>
                      <circle cx="76.707" cy="39.1035" r="1.5"></circle>
                      <circle cx="76.707" cy="51.6406" r="1.5"></circle>
                      <circle cx="76.707" cy="64.1758" r="1.5"></circle>
                      <circle cx="76.707" cy="76.709" r="1.5"></circle>
                      <circle cx="76.707" cy="89.2441" r="1.5"></circle>
                      <circle cx="76.707" cy="101.779" r="1.5"></circle>
                      <circle cx="76.707" cy="114.314" r="1.5"></circle>
                      <circle cx="89.2461" cy="1.5" r="1.5"></circle>
                      <circle cx="89.2461" cy="14.0352" r="1.5"></circle>
                      <circle cx="89.2461" cy="26.5684" r="1.5"></circle>
                      <circle cx="89.2461" cy="39.1035" r="1.5"></circle>
                      <circle cx="89.2461" cy="51.6406" r="1.5"></circle>
                      <circle cx="89.2461" cy="64.1758" r="1.5"></circle>
                      <circle cx="89.2461" cy="76.709" r="1.5"></circle>
                      <circle cx="89.2461" cy="89.2441" r="1.5"></circle>
                      <circle cx="89.2461" cy="101.779" r="1.5"></circle>
                      <circle cx="89.2461" cy="114.314" r="1.5"></circle>
                      <circle cx="101.777" cy="1.5" r="1.5"></circle>
                      <circle cx="101.777" cy="14.0352" r="1.5"></circle>
                      <circle cx="101.777" cy="26.5684" r="1.5"></circle>
                      <circle cx="101.777" cy="39.1035" r="1.5"></circle>
                      <circle cx="101.777" cy="51.6406" r="1.5"></circle>
                      <circle cx="101.777" cy="64.1758" r="1.5"></circle>
                      <circle cx="101.777" cy="76.709" r="1.5"></circle>
                      <circle cx="101.777" cy="89.2441" r="1.5"></circle>
                      <circle cx="101.777" cy="101.779" r="1.5"></circle>
                      <circle cx="101.777" cy="114.314" r="1.5"></circle>
                      <circle cx="114.312" cy="1.5" r="1.5"></circle>
                      <circle cx="114.312" cy="14.0352" r="1.5"></circle>
                      <circle cx="114.312" cy="26.5684" r="1.5"></circle>
                      <circle cx="114.312" cy="39.1035" r="1.5"></circle>
                      <circle cx="114.312" cy="51.6406" r="1.5"></circle>
                      <circle cx="114.312" cy="64.1758" r="1.5"></circle>
                      <circle cx="114.312" cy="76.709" r="1.5"></circle>
                      <circle cx="114.312" cy="89.2441" r="1.5"></circle>
                      <circle cx="114.312" cy="101.779" r="1.5"></circle>
                      <circle cx="114.312" cy="114.314" r="1.5"></circle>
                      <circle cx="126.848" cy="1.5" r="1.5"></circle>
                      <circle cx="126.848" cy="14.0352" r="1.5"></circle>
                      <circle cx="126.848" cy="26.5684" r="1.5"></circle>
                      <circle cx="126.848" cy="39.1035" r="1.5"></circle>
                      <circle cx="126.848" cy="51.6406" r="1.5"></circle>
                      <circle cx="126.848" cy="64.1758" r="1.5"></circle>
                      <circle cx="126.848" cy="76.709" r="1.5"></circle>
                      <circle cx="126.848" cy="89.2441" r="1.5"></circle>
                      <circle cx="126.848" cy="101.779" r="1.5"></circle>
                      <circle cx="126.848" cy="114.314" r="1.5"></circle>
                      <circle cx="139.383" cy="1.5" r="1.5"></circle>
                      <circle cx="139.383" cy="14.0352" r="1.5"></circle>
                      <circle cx="139.383" cy="26.5684" r="1.5"></circle>
                      <circle cx="139.383" cy="39.1035" r="1.5"></circle>
                      <circle cx="139.383" cy="51.6406" r="1.5"></circle>
                      <circle cx="139.383" cy="64.1758" r="1.5"></circle>
                      <circle cx="139.383" cy="76.709" r="1.5"></circle>
                      <circle cx="139.383" cy="89.2441" r="1.5"></circle>
                      <circle cx="139.383" cy="101.779" r="1.5"></circle>
                      <circle cx="139.383" cy="114.314" r="1.5"></circle>
                      <circle cx="151.918" cy="1.5" r="1.5"></circle>
                      <circle cx="151.918" cy="14.0352" r="1.5"></circle>
                      <circle cx="151.918" cy="26.5684" r="1.5"></circle>
                      <circle cx="151.918" cy="39.1035" r="1.5"></circle>
                      <circle cx="151.918" cy="51.6406" r="1.5"></circle>
                      <circle cx="151.918" cy="64.1758" r="1.5"></circle>
                      <circle cx="151.918" cy="76.709" r="1.5"></circle>
                      <circle cx="151.918" cy="89.2441" r="1.5"></circle>
                      <circle cx="151.918" cy="101.779" r="1.5"></circle>
                      <circle cx="151.918" cy="114.314" r="1.5"></circle>
                      <circle cx="164.453" cy="1.5" r="1.5"></circle>
                      <circle cx="164.453" cy="14.0352" r="1.5"></circle>
                      <circle cx="164.453" cy="26.5684" r="1.5"></circle>
                      <circle cx="164.453" cy="39.1035" r="1.5"></circle>
                      <circle cx="164.453" cy="51.6406" r="1.5"></circle>
                      <circle cx="164.453" cy="64.1758" r="1.5"></circle>
                      <circle cx="164.453" cy="76.709" r="1.5"></circle>
                      <circle cx="164.453" cy="89.2441" r="1.5"></circle>
                      <circle cx="164.453" cy="101.779" r="1.5"></circle>
                      <circle cx="164.453" cy="114.314" r="1.5"></circle>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
