
import { Style } from 'react-style-tag';

function Contact()
{
  return (
    <section className="elementor-section elementor-top-section elementor-element elementor-element-37156f0e elementor-section-height-min-height elementor-section-items-stretch elementor-section-boxed elementor-section-height-default"
             data-id="37156f0e" data-element_type="section"
             data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;1.5&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_translateY_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:0,&quot;end&quot;:100}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}">
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-27e25280"
             data-id="27e25280" data-element_type="column"
             data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-209e461 elementor-absolute elementor-widget elementor-widget-menu-anchor"
                 data-id="209e461" data-element_type="widget"
                 data-settings="{&quot;_position&quot;:&quot;absolute&quot;}"
                 data-widget_type="menu-anchor.default">
              <div className="elementor-widget-container">
                <Style>{`
                  body.elementor-page .elementor-widget-menu-anchor {
                    margin-bottom: 0
                  }
                `}</Style>
                <div id="contact-us" className="elementor-menu-anchor">
                </div>
              </div>
            </div>
            <div className="elementor-element elementor-element-320896c8 elementor-widget-divider--separator-type-pattern elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                 data-id="320896c8" data-element_type="widget" data-widget_type="divider.default">
              <div className="elementor-widget-container">
                <div className="elementor-divider my-divider-pattern-7">
                  <span className="elementor-divider-separator">
                  </span>
                </div>
              </div>
            </div>

            <div className="elementor-element elementor-element-3fad4ed4 elementor-widget elementor-widget-heading"
                 data-id="3fad4ed4" data-element_type="widget"
                 data-widget_type="heading.default">
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  Where You Can Find Us
                </h2>
              </div>
            </div>

            <section className="elementor-section elementor-inner-section elementor-element elementor-element-441f1c0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                     data-id="441f1c0" data-element_type="section">
              <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1a9da84"
                     data-id="1a9da84" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div className="elementor-element elementor-element-3666585 elementor-widget elementor-widget-heading"
                         data-id="3666585" data-element_type="widget"
                         data-widget_type="heading.default">
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          01.
                        </h2>
                      </div>
                    </div>
                    <div className="elementor-element elementor-element-e4046b4 elementor-widget elementor-widget-icon-box"
                         data-id="e4046b4" data-element_type="widget"
                         data-widget_type="icon-box.default">
                      <div className="elementor-widget-container">
                        <link rel="stylesheet" href="./css/widget-icon-box.min.css" />
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-content">
                            <h3 className="elementor-icon-box-title">
                              <span>
                                Phone Number
                              </span>
                            </h3>
                            <p className="elementor-icon-box-description">
                              <a href="tel:6128688036">(612) 868 8036</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5d14f33"
                     data-id="5d14f33" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div className="elementor-element elementor-element-aec4c45 elementor-widget elementor-widget-heading"
                         data-id="aec4c45" data-element_type="widget"
                         data-widget_type="heading.default">
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          02.
                        </h2>
                      </div>
                    </div>
                    <div className="elementor-element elementor-element-f6dcdc3 elementor-widget elementor-widget-icon-box"
                         data-id="f6dcdc3" data-element_type="widget"
                         data-widget_type="icon-box.default">
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-content">
                            <h3 className="elementor-icon-box-title">
                              <span>
                                Our Email
                              </span>
                            </h3>
                            <p className="elementor-icon-box-description">
                              <a href="mailto:info@digitalrocket.com">
                                info@digitalrocket.com
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1a9da84"
                     data-id="1a9da84" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div className="elementor-element elementor-element-f6dcdc3 elementor-widget"
                         data-id="f6dcdc3" data-widget_type="icon-box.default">
                      <p className="elementor-icon-box-description">
                        <a href="https://www.youtube.com/@digitalrocketscience" target="_blank">
                          <img width='110px' height='20px' src="./images/yt_logo_rgb_light_ss.png"
                               className='my-image-link-1' />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
