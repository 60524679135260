
function Products()
{
  return (
    <section className="elementor-section elementor-top-section elementor-element elementor-element-12f2772d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
             data-id="12f2772d" data-element_type="section" id="education-products"
             data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shape_divider_top&quot;:&quot;tilt&quot;,&quot;shape_divider_bottom&quot;:&quot;tilt&quot;}">
      <div className="elementor-shape elementor-shape-top" data-negative="false">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
          <path className="elementor-shape-fill" d="M0,6V0h1000v100L0,6z" />
        </svg>
      </div>
      <div className="elementor-shape elementor-shape-bottom" data-negative="false">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
          <path className="elementor-shape-fill" d="M0,6V0h1000v100L0,6z" />
        </svg>
      </div>
      <div className="elementor-container elementor-column-gap-no">
        <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-570698ab"
             data-id="570698ab" data-element_type="column">
          <div className="elementor-widget-wrap elementor-element-populated">
            <div className="elementor-element elementor-element-541e74a elementor-widget-divider--separator-type-pattern elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                 data-id="541e74a" data-element_type="widget" data-widget_type="divider.default">
              <div className="elementor-widget-container">
                <div className="elementor-divider my-divider-pattern-3">
                  <span className="elementor-divider-separator">
                  </span>
                </div>
              </div>
            </div>
            <div className="elementor-element elementor-element-2f7c200 elementor-widget elementor-widget-heading"
                 data-id="2f7c200" data-element_type="widget" data-widget_type="heading.default">
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  Education Products
                </h2>
              </div>
            </div>

            <section className="elementor-section elementor-inner-section elementor-element elementor-element-300ff40 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                     data-id="300ff40" data-element_type="section">
              <div className="elementor-container elementor-column-gap-custom">
                <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-ef0ec11"
                     data-id="ef0ec11" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div className="elementor-element elementor-element-e154bbd elementor-cta--skin-classic elementor-animated-content elementor-bg-transform elementor-bg-transform-zoom-in elementor-widget elementor-widget-call-to-action"
                         data-id="e154bbd" data-element_type="widget"
                         data-widget_type="call-to-action.default">
                      <div className="elementor-widget-container">
                        <link rel="stylesheet" href="./css/widget-call-to-action.min.css" />
                        <div className="elementor-cta">
                          <div className="elementor-cta__content">
                            <div className="elementor-content-item elementor-cta__content-item elementor-cta__image">
                              <img decoding="async" loading="lazy" width="2917" height="1672"
                                   src="./images/1649124244288-Digital-AI-FINAL-WEB-VERSION.png"
                                   className="attachment-full size-full wp-image-83" alt=""
                                   srcSet="./images/1649124244288-Digital-AI-FINAL-WEB-VERSION.png 2917w, ./images/1649124244288-Digital-AI-FINAL-WEB-VERSION-300x172.png 300w, ./images/1649124244288-Digital-AI-FINAL-WEB-VERSION-1024x587.png 1024w, ./images/1649124244288-Digital-AI-FINAL-WEB-VERSION-768x440.png 768w, ./images/1649124244288-Digital-AI-FINAL-WEB-VERSION-1536x880.png 1536w, ./images/1649124244288-Digital-AI-FINAL-WEB-VERSION-2048x1174.png 2048w"
                                   sizes="(max-width: 2917px) 100vw, 2917px" />
                            </div>

                            <div className="elementor-cta__description elementor-cta__content-item elementor-content-item">
                              An interactive and highly visual artificial
                              intelligence and machine learning platform for
                              training and inference of a wide variety of
                              interesting applications.
                            </div>

                            <div className="elementor-cta__button-wrapper elementor-cta__content-item elementor-content-item ">
                              <a className="elementor-cta__button elementor-button elementor-size-sm"
                                 href="https://www.digitalai.studio/" target="_blank">
                                Coming Soon
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-34cc8e7"
                     data-id="34cc8e7" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div className="elementor-element elementor-element-556cb15 elementor-cta--skin-classic elementor-animated-content elementor-bg-transform elementor-bg-transform-zoom-in elementor-widget elementor-widget-call-to-action"
                         data-id="556cb15" data-element_type="widget"
                         data-widget_type="call-to-action.default">
                      <div className="elementor-widget-container">
                        <div className="elementor-cta">
                          <div className="elementor-cta__content">
                            <div className="elementor-content-item elementor-cta__content-item elementor-cta__image">
                              <img decoding="async" loading="lazy" width="1164" height="536"
                                   src="./images/1649124220512-digitaldynamics-01.png"
                                   className="attachment-full size-full wp-image-82" alt=""
                                   srcSet="./images/1649124220512-digitaldynamics-01.png 1164w, ./images/1649124220512-digitaldynamics-01-300x138.png 300w, ./images/1649124220512-digitaldynamics-01-1024x472.png 1024w, ./images/1649124220512-digitaldynamics-01-768x354.png 768w"
                                   sizes="(max-width: 1164px) 100vw, 1164px" />
                            </div>

                            <div className="elementor-cta__description elementor-cta__content-item elementor-content-item">
                              An interactive computational fluid dynamics
                              simulation platform enabling real-time
                              experimentation of a wide variety of fluid dynamics
                              applications.
                            </div>

                            <div className="elementor-cta__button-wrapper elementor-cta__content-item elementor-content-item ">
                              <a className="elementor-cta__button elementor-button elementor-size-sm"
                                 href="https://www.digitaldynamics.studio/" target="_blank">
                                Learn More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-e59f767"
                     data-id="e59f767" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div className="elementor-element elementor-element-d7ae81e elementor-cta--skin-classic elementor-animated-content elementor-bg-transform elementor-bg-transform-zoom-in elementor-widget elementor-widget-call-to-action"
                         data-id="d7ae81e" data-element_type="widget"
                         data-widget_type="call-to-action.default">
                      <div className="elementor-widget-container">
                        <div className="elementor-cta">
                          <div className="elementor-cta__content">
                            <div className="elementor-content-item elementor-cta__content-item elementor-cta__image">
                              <img decoding="async" loading="lazy" width="301" height="139"
                                   src="./images/digital-planet-logo-03.svg"
                                   className="attachment-full size-full wp-image-147" alt="" />
                            </div>

                            <div className="elementor-cta__description elementor-cta__content-item elementor-content-item">
                              A collaborative global climate and weather model
                              where students interact with and experiment with
                              features and parameters on a planet-wide scale.
                            </div>

                            <div className="elementor-cta__button-wrapper elementor-cta__content-item elementor-content-item ">
                              <a className="elementor-cta__button elementor-button elementor-size-sm"
                                 href="https://digitalplanet.studio/" target="_blank">
                                Coming Soon
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Products;
